import React, { Component } from 'react';
import './App.css';
import comingSoon from './coming-soon.png';
import realestate from './realestatePH.png';

class App extends Component {
  render() {
    return (
      <div className="App">
          <div className="inner">
              <img
                  style={{ marginBottom: 20, width: 624 }}
                  src={realestate}
                  alt="realestate.ph"
              />
              <img
                  style={{ width: 350 }}
                  src={comingSoon}
                  alt="coming soon"
              />
          </div>
          <div className="footer">
              <p>
                  All rights reserve 2018&nbsp; @&nbsp;
                  <a href="http://rushmedia.ph" rel="nofollow">Rushmedia.ph</a>
              </p>
          </div>
      </div>
    );
  }
}

export default App;
